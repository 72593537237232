import { Button, Grid, IconButton } from '@material-ui/core';

import { AyudaLinearIcon, Input, Modal, Typography } from '@saesa/ui-kit-front';
import { tooltipServicio } from 'assets/images';
import Redireccion from 'components/Footer/Redireccion/Redireccion';
import {
  TipoEstadoActualTrackingCorteEnum,
  TipoEtapaPostVenta,
  TipoEtapaTrackingEnum,
  TipoPagoBoleta,
  TipoTrackingCorteEnum,
  TipoTrackingEnum,
} from 'config';
import { RootValoresInicialesTrackingModel } from 'models';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useStyles from 'pages/Styles/VistasCorteStyles';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CICLO_FACTURACION, CICLO_FACTURACION_SLASH } from 'navigation';
import TrackingService from 'services/TrackingService';
import { mostrarModalCookie } from 'utils/cookieUtils';
import { milisegundosPorMinuto } from 'utils/constants';
import ModalSitioNoDisponible from 'components/ModalSitioNoDisponible/ModalSitioNoDisponible';
import {
  CLIENTEHOGAR,
  CLIENTEMPRESA,
  CONTACTO,
  GRUPOSAESA,
  HERRAMIENTAS,
  INDICADORES,
  INVERSIONISTA,
  QUEINESOMOS,
  SOMOSVECINOS,
} from 'root-page';
import { actualizarEstadoFlag, actualizarEstadoModalPaginaNoDisponible, RootState, TrackingState } from 'store';
import WidgetTrackingHelper from './WidgetTrackingHelper/WidgetTrackingHelper';

/**
 * Inicializa el objeto trackingState
 */
export const INITIAL_STATE: TrackingState = {
  distribuidora: {
    urlLogo: '',
  },
  usuario: {
    codigoEstadoSuministro: '',
    codDistribuidora: '',
    comuna: '',
    numeroServicio: '',
    localidad: '',
  },
  facturacion: {
    ultimoPago: {
      fechaBoleta: '',
      fechaEmision: '',
      fechaVencimiento: '',
      linkPaperlessBoleta: '',
      montoPago: '',
      numeroBoleta: '',
    },
    cobroActual: {
      codEmpresa: '',
      estadoPago: TipoPagoBoleta.DEFAULT,
      fechaEmisionBoleta: '',
      fechaVencimiento: '',
      linkPaperlessBoleta: '',
      montoPago: '',
      numeroBoleta: '',
    },
    penultimaBoleta: {
      estadoPago: TipoPagoBoleta.DEFAULT,
      fechaEmision: '',
      fechaVencimiento: '',
      linkPaperlessBoleta: '',
      montoBoleta: '',
      numeroBoleta: '',
    },
    codEmpresa: '',
    linkPaperlessBoleta: '',
    suscripcionBoletaElectronica: false,
  },
  ciclo: {
    activo: false,
    datos: [],
    estado: TipoEtapaTrackingEnum.DEFAULT,
    estadosAnteriores: [],
    etiquetas: [],
    fechaCierre: '',
    fechaCreacion: '',
    fechaEliminacion: '',
    fechaMaxima: '',
    id: '',
    tipo: '',
  },
  corte: {
    clientesAfectadosTotales: '',
    clientesNormalizados: '',
    estadoActual: TipoEstadoActualTrackingCorteEnum.DEFAULT,
    fechaArriboBrigada: '',
    fechaCreacionCaso: '',
    fechaFinCorte: '',
    fechaInicioCorte: '',
    motivo: '',
    tipo: TipoTrackingCorteEnum.DEFAULT,
  },
  postventa: {
    ticketSeleccionado: {
      id: '',
      tipo: '',
      motivo: '',
      fechaIngresado: '',
      fechaFinalizado: '',
      estado: TipoEtapaPostVenta.DEFAULT,
      fechaCreacionDate: '',
    },
    tickets: [],
    estados: [],
  },
  menuAbierto: false,
  dataTramo: {
    tramo: '',
    promedioConsumoKWH: '',
    promedioConsumoPesos: '',
    promedioConsumoPesosFormateado: '',
    promedioConsumoPesosEntero: '',
  },
  flagMPC: {
    widget: false,
    modal: false,
  },
  modalPaginaNoDisponible: {
    tiempoCookie: 0,
    activacionModal: false,
  },
  flagCargado: false,
  flagCargadoPostVenta: false,
};

/**
 * Arreglo que contiene menu de navegacion de menu tope del header
 */
export const obtenerNavMenu = (t: any) => [
  {
    id: 0,
    option: t('tracking:paginas.tracking.cicloFacturacion.home.menu.grupoSaesa'),
    url: GRUPOSAESA,
  },
  {
    id: 1,
    option: t('tracking:paginas.tracking.cicloFacturacion.home.menu.quienesSomos'),
    url: QUEINESOMOS,
  },
  {
    id: 2,
    option: t('tracking:paginas.tracking.cicloFacturacion.home.menu.inversionistas'),
    url: INVERSIONISTA,
  },
  {
    id: 3,
    option: t('tracking:paginas.tracking.cicloFacturacion.home.menu.indicadores'),
    url: INDICADORES,
  },
  {
    id: 4,
    option: t('tracking:paginas.tracking.cicloFacturacion.home.menu.herramientas'),
    url: HERRAMIENTAS,
  },
  {
    id: 5,
    option: t('tracking:paginas.tracking.cicloFacturacion.home.menu.contacto'),
    url: CONTACTO,
  },
  {
    id: 6,
    option: t('tracking:paginas.tracking.cicloFacturacion.home.menu.somosVecinos'),
    url: SOMOSVECINOS,
  },
  {
    id: 7,
    option: t('tracking:paginas.tracking.cicloFacturacion.home.menu.clienteHogar'),
    url: CLIENTEHOGAR,
  },
  {
    id: 8,
    option: t('tracking:paginas.tracking.cicloFacturacion.home.menu.clienteEmpresa'),
    url: CLIENTEMPRESA,
  },
];

export const obtenerContenedorFooterRedireccionXs6Md12 = (styles: any, texto: string, link: string) => (
  <Grid item xs={6} md={12}>
    <Redireccion className={styles.textoLista} texto={texto} variant="parrafo" link={link} />
  </Grid>
);

export const obtenerContenedorFooterRedireccionXs12 = (styles: any, texto: string, link: string) => (
  <Grid item xs={12}>
    <Redireccion className={styles.textoLista} texto={texto} variant="parrafo" link={link} />
  </Grid>
);

export const initValoresRoot = (setMostrarModal: (value: boolean) => void) => {
  /**
   * Componente history gestor ruteos
   */
  const history = useHistory();
  /**
   * Funcion dispatch set redux
   */
  const dispatch = useDispatch();
  /**
   * Uso del i18n para traducciones
   */
  const { t } = useTranslation();
  /**
   * Componente de hoja de estilos
   */
  const styles = useStyles();
  /**
   * Componente de pildora
   */
  const { enqueueSnackbar } = useSnackbar();
  /**
   * Valor de formik
   */
  let formik: any;
  /**
   * Cerrar modal
   */
  const cerrarModal = () => {
    setMostrarModal(false);
  };
  return { history, dispatch, t, styles, cerrarModal, enqueueSnackbar, formik };
};

export const rootContenedorDetalle = (valoresInciales: RootValoresInicialesTrackingModel) => {
  /**
   * Hook que permite obtener la ruta actual
   */
  const { pathname } = useLocation();

  /**
   * Disparador para el selector
   */
  const dispatch = useDispatch();

  /**
   * UseState que controla el estado del modal de no disponible
   */
  const [modalNoDisponible, setModalNoDisponible] = useState<boolean>(false);

  /**
   * Obtiene el valor del flag del estado modal no disponible
   */
  const modalNoDisponibleFlagVisible = useSelector((state: RootState) => state.tracking.modalPaginaNoDisponible.activacionModal);

  /**
   * Obtiene el valor del flag del valor en minutos de la cookie para el modal no disponible
   */
  const valorMinutosModal: number = useSelector((state: RootState) => state.tracking.modalPaginaNoDisponible.tiempoCookie);

  /**
   * Obtiene el valor del flag estado de carga del flag
   */
  const flagCargado: boolean = useSelector((state: RootState) => state.tracking.flagCargado);

  /**
   * Constante que contiene el tiempo para mostrar el modal
   */
  const tiempoMostrarModal = valorMinutosModal * milisegundosPorMinuto;

  /**
   * Obtiene el flag
   */
  const obtenerFlag = () => {
    TrackingService.obtenerFeatureFlag()
      .then((response: any) => {
        dispatch(actualizarEstadoModalPaginaNoDisponible(response.web.modalSitioNoDisponible));
        dispatch(actualizarEstadoFlag(true));
      })
      .catch(() => {
        dispatch(actualizarEstadoModalPaginaNoDisponible({ tiempoCookie: 0, activacionModal: false }));
        dispatch(actualizarEstadoFlag(false));
      });
  };

  /**
   * Selector de data del flag de mpc del widget
   */
  const FLAG_MPC_WIDGET = useSelector((state: RootState) => state.tracking.flagMPC.widget);

  /**
   * Enviroment de google catpcha
   */
  const googleCaptchaKey = process.env.REACT_APP_CAPTCHA_KEY || '';

  /**
   * Función que establece si el usuario esta en facturacion o en facturacion/
   * @returns boleano
   */
  function isFacturacionPath(): boolean {
    return pathname === (CICLO_FACTURACION || pathname === CICLO_FACTURACION_SLASH) && FLAG_MPC_WIDGET;
  }

  /**
   * Función que establece si el usuario esta en facturacion o en facturacion/ sin importar widget
   * @returns boleano
   */
  function isFacturacion(): boolean {
    return pathname === CICLO_FACTURACION || pathname === CICLO_FACTURACION_SLASH;
  }

  /**
   * useEffect principal
   */
  useEffect(() => {
    obtenerFlag();
  }, []);

  /**
   * UseEffect que maneja el estado de la cookie del modal
   */
  useEffect(() => {
    if (valorMinutosModal > 0 && modalNoDisponibleFlagVisible && isFacturacion()) {
      setModalNoDisponible(mostrarModalCookie('cookieFacturacion', tiempoMostrarModal));
    }
  }, [flagCargado]);

  /**
   * Función para cerrar modal de no disponible
   */
  const cerrarModalNoDisponible = () => {
    setModalNoDisponible(false);
  };

  /**
   * Constante que contiene el estado del modal
   */
  const modalActivo = modalNoDisponible && modalNoDisponibleFlagVisible && isFacturacion();

  return (
    <section className={valoresInciales.className} data-testid="contendor-principal">
      {modalActivo && <ModalSitioNoDisponible cerrarModal={cerrarModalNoDisponible} />}
      <section className={isFacturacionPath() ? valoresInciales.styles.textosFacturacion : valoresInciales.styles.textos}>
        <Typography
          className={isFacturacionPath() ? valoresInciales.styles.descripcionFacturacion : valoresInciales.styles.descripcion}
          variant="parrafo"
        >
          {valoresInciales.tipoTracking === TipoTrackingEnum.CICLO_FACTURACION
            ? valoresInciales.t('tracking:paginas.tracking.cicloFacturacion.contenido.descripcion')
            : valoresInciales.t('tracking:paginas.tracking.corte.descripcion')}
        </Typography>
      </section>
      {isFacturacionPath() && <WidgetTrackingHelper />}

      <form className={valoresInciales.styles.formCnt} onSubmit={valoresInciales.formik.handleSubmit}>
        <section data-testid="sectionNServicio" className={valoresInciales.styles.numeroServicio}>
          <Input
            dataTestId="numServicio"
            name="numServicio"
            value={valoresInciales.formik.values.numServicio}
            onBlur={valoresInciales.formik.handleBlur}
            onChange={valoresInciales.formik.handleChange}
            touched={valoresInciales.formik.touched.numServicio}
            errors={valoresInciales.formik.errors.numServicio}
            label={valoresInciales.t('tracking:paginas.tracking.cicloFacturacion.contenido.formulario.numeroServicio.label')}
            placeholder={valoresInciales.t('tracking:paginas.tracking.cicloFacturacion.contenido.formulario.numeroServicio.placeholder')}
            autocomplete="off"
            endAdornment={
              <>
                <IconButton
                  className={valoresInciales.styles.tooltip}
                  aria-label="menu"
                  onClick={() => valoresInciales.setMostrarModal(!valoresInciales.mostrarModal)}
                >
                  <AyudaLinearIcon className={valoresInciales.styles.tooltipIcon} />
                </IconButton>
              </>
            }
          />
        </section>{' '}
        <ReCAPTCHA
          className={valoresInciales.styles.recaptcha}
          ref={refCaptcha => valoresInciales.setCaptchaRef(refCaptcha)}
          data-testid="recaptcha"
          sitekey={googleCaptchaKey}
          onChange={value => {
            valoresInciales.formik.setFieldValue('captcha', value);
            valoresInciales.formik.setSubmitting(false);
          }}
        />
        <Button
          className={valoresInciales.styles.botonIr}
          variant="contained"
          color="primary"
          type="submit"
          data-testid="submit"
          disabled={!(valoresInciales.formik.isValid && valoresInciales.formik.dirty)}
        >
          {valoresInciales.t('tracking:paginas.tracking.cicloFacturacion.contenido.botones.ir')}
        </Button>
      </form>
      {valoresInciales.mostrarModal ? (
        <Modal cerrarModal={valoresInciales.cerrarModal}>
          <img className={valoresInciales.styles.imgTooltip} src={tooltipServicio} alt="" />
        </Modal>
      ) : null}
    </section>
  );
};
