import { Hidden } from '@material-ui/core';
import { Header, Sidemenu, TopHeader } from 'components';
import Footer from 'components/Footer/Footer';
import { ErrorInterceptor } from 'config';
import { CICLO_FACTURACION, CORTE, POST_VENTA, ROOT } from 'navigation';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RootCicloFacturacion, RootCorte, RootPostVenta } from 'root-page';
import useStyles from '../root-page/RootStyles/RootStyles';

const RootRouterConfig = () => {
  /**
   * styles
   */
  const styles = useStyles();

  const { t } = useTranslation();

  ErrorInterceptor(t);

  return (
    <section className={styles.root}>
      <TopHeader className={styles.lineaTope} />
      <Hidden only={['md', 'lg', 'xl']}>
        <Sidemenu />
      </Hidden>
      <Header className={styles.header} />
      <section className={styles.content}>
        <section className={styles.tracking}>
          <Switch>
            <Redirect path={ROOT} exact to={CICLO_FACTURACION} />
            <Route path={CICLO_FACTURACION}>
              <RootCicloFacturacion path={CICLO_FACTURACION} />
            </Route>
            <Route path={CORTE}>
              <RootCorte path={CORTE} />
            </Route>
            <Route path={POST_VENTA}>
              <RootPostVenta path={POST_VENTA} />
            </Route>
          </Switch>
        </section>
      </section>
      <Footer className={styles.footer} />
    </section>
  );
};

export default RootRouterConfig;
