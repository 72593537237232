import axios from 'axios';
import { HttpStatusCodeEnum } from 'config/HttpStatusCodeEnum';
import { TokenRequestTrackingModel } from 'models';
import { ERROR_N_SERVICIO_NO_EXISTE, ERROR_N_SERVICIO_SIN_INSTANCIA } from 'navigation';
import { MedioPagoResponse } from 'models/MedioPagoResponse';
import { TicketPostVentaModel } from '../models/TicketPostVentaModel';
import {
  FacturacionResponseModel,
  InfoClienteResponseTrackingModel,
  TokenResponseTrackingModel,
  TrackingCorteEstadosResponseModel,
  TrackingEstadosPorIdResponseModel,
  TrackingEtiquetaResponseModel,
  TramoTracking,
} from './models';

class TrackingService {
  /**
   * Metodo que obtiene el token de la app
   * @param data request para token
   * @returns token tracking
   */
  static obtenerTokenTracking(data: TokenRequestTrackingModel): Promise<TokenResponseTrackingModel> {
    return axios.post(`${process.env.REACT_APP_TRACKING_URL}/public/token`, data).then((response: any) => Promise.resolve(response.data));
  }

  /**
   * Obtiene el estado actual de la contingencia
   * URL MOCK: http://saesa.mockable.io/public/10136962/ubicacion
   * @returns retorna true o false
   */
  static obtenerInfoCliente(nroServicio: string): Promise<InfoClienteResponseTrackingModel | any> {
    return axios
      .get(`${process.env.REACT_APP_TRACKING_URL}/public/${nroServicio}/ubicacion`)
      .then((response: any) => Promise.resolve(response.data))
      .catch(error => {
        if (error?.response?.status === HttpStatusCodeEnum.NOT_FOUND) {
          throw new Error(ERROR_N_SERVICIO_NO_EXISTE);
        }
      });
  }

  /**
   * Obtiene la facturación de cliente
   * URL MOCK: http://saesa.mockable.io/public/facturacion/resumen/${nroServicio}
   * @returns retorna true o false
   */
  static obtenerFacturacionTracking(nroServicio: string): Promise<FacturacionResponseModel> {
    return axios
      .get(`${process.env.REACT_APP_TRACKING_URL}/public/facturacion/resumen/${nroServicio}`)
      .then((response: any) => Promise.resolve(response.data));
  }

  /**
   * Obtiene la tramo de cliente
   * @returns retorna respuesta del tramo
   */
  static obtenerTramoTracking(nroServicio: string): Promise<TramoTracking> {
    return axios
      .get(`${process.env.REACT_APP_TRACKING_URL}/public/facturacion/tramo/servicio/${nroServicio}`)
      .then((response: any) => Promise.resolve(response.data));
  }

  /**
   * Obtiene el estado actual de la contingencia
   * URL MOCK: http://saesa.mockable.io/inspira/tracking/qas/v1/tracking/private/etiqueta?etiquetas=numeroServicio|${numeroServicio}&tipo=CICLO_COMERCIAL
   * @returns retorna true o false
   */
  static obtenerTrackingEtiqueta(numeroServicio: string): Promise<TrackingEtiquetaResponseModel> {
    return axios
      .get(`${process.env.REACT_APP_TRACKING_URL}/public/etiqueta?etiquetas=numeroServicio|${numeroServicio}&tipo=CICLO_COMERCIAL`)
      .then((response: any) => Promise.resolve(response.data))
      .catch(error => {
        if (error?.response?.status === HttpStatusCodeEnum.NOT_FOUND) {
          throw new Error(ERROR_N_SERVICIO_SIN_INSTANCIA);
        }
      });
  }

  /**
   * Obtiene el estado actual de la contingencia
   * URL MOCK: http://saesa.mockable.io/tracking/dev/v1/tracking/public/${idEtiqueta}
   * @returns retorna true o false
   */
  static obtenerEstadosTrackingPorId(idEtiqueta: string): Promise<TrackingEstadosPorIdResponseModel> {
    return axios.get(`${process.env.REACT_APP_TRACKING_URL}/public/${idEtiqueta}`).then((response: any) => Promise.resolve(response.data));
  }

  /**
   * Obtiene el estado actual de tracking corte
   * @returns observable de respuesta servicio
   */
  static obtenerEstadosTrackingCortes(nroServicio: string): Promise<TrackingCorteEstadosResponseModel> {
    return axios
      .get(`${process.env.REACT_APP_TRACKING_URL}/public/cortes/${nroServicio}`)
      .then((response: any) => Promise.resolve(response.data));
  }

  /**
   * Obtiene los estados disponibles de tickets en postventa.
   *
   * @returns observable de respuesta servicio
   */
  static obtenerEstadosTrackingPostventa(): Promise<string[]> {
    return axios
      .get(`${process.env.REACT_APP_TRACKING_URL}/public/postventa/estados`)
      .then((response: any) => Promise.resolve(response.data));
  }

  /**
   * Obtiene los tickets en postventa.
   *
   * @returns observable de respuesta servicio
   */
  static obtenerTicketsTrackingPostventa(nroServicio: string, filtroAnio: any, filtroEstado: any): Promise<TicketPostVentaModel[]> {
    const params = {
      numeroServicio: nroServicio,
      fecha: filtroAnio,
      estado: filtroEstado,
    };

    return axios
      .get(`${process.env.REACT_APP_TRACKING_URL}/public/postventa/tickets`, { params })
      .then((response: any) => Promise.resolve(response.data));
  }

  /**
   * Obtiene identificador para asociar a url de pago.
   *
   * @param numeroServicio Numero de servicio a obtener id para pago
   * @returns Respuesta con id para pago
   */
  static validarMedioPago(numeroServicio: string): Promise<MedioPagoResponse> {
    const canal = 'WEB';
    return axios
      .get(`${process.env.REACT_APP_TRACKING_URL}/public/facturacion/pago/${numeroServicio}/portalpago?canal=${canal}`)
      .then(response => new Promise(resolve => resolve(response.data)));
  }

  /**
   * Obtiene habilitadores generales de feature en la web
   * @returns retorna habilitadores para distintos feature
   */
  static obtenerFeatureFlag(): Promise<any> {
    return axios.get(`${process.env.REACT_APP_ASSETS_URL}/data/featureFlag.json`).then((response: any) => Promise.resolve(response.data));
  }
}
export default TrackingService;
