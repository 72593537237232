import { createStyles, makeStyles, Theme } from '@material-ui/core';

const PagesItemStyle = makeStyles((theme: Theme) =>
  createStyles({
    pagesItem: {
      '--ancho-maximo-grid': '1090px',
      maxWidth: 'var(--ancho-maximo-grid)',
      margin: '0 auto',
      display: 'flex',
      flexFlow: 'column',
      boxShadow: '0px 0.125rem 1.25rem hsla(var(--valor-primario-azul-oscuro), 0.14)',
      borderRadius: '1.25rem',
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
        marginBottom: '1rem',
      },
    },
    flecha: {
      '--icon-primary-dark': 'var(--primario-morado)',
      marginRight: '0.625rem',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: '1.875rem 0 1.875rem 0',
      margin: '0 1.75rem',
      borderBottom: '1px solid var(--secundario-gris-azulado)',
      [theme.breakpoints.down('sm')]: {
        padding: '1.125rem 5% 1.375rem 5%',
        width: '100%',
        margin: '0',
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      color: 'var(--primario-morado)',
      justifyContent: 'center',
      flexGrow: 1,
    },
    titleTramo: {
      display: 'flex',
      alignItems: 'center',
      color: '#074177',
      justifyContent: 'center',
      size: '21px',
      fontWeight: 700,
      flexGrow: 1,
    },
    body: {
      height: '100%',
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2rem 2.375rem 3rem 2.375rem',
      [theme.breakpoints.down('sm')]: {
        padding: '1.5rem 1.25rem 3rem 1.25rem',
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '1.5rem 1.25rem 2rem 1.25rem',
      },
    },
    boton: {
      position: 'absolute',
      padding: '0',
      [theme.breakpoints.down('sm')]: {
        left: '0.9rem',
      },
    },
    ocultar: {
      display: 'none',
    },
  })
);

export default PagesItemStyle;
